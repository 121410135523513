import axios from "axios";
import { URL } from "../datas/common";
import { store } from "../modules";
import { set_header_info, set_token, set_user_index } from "../modules/local";
import api from "../utils/api";
import { useQuery } from "react-query";

export const onSilentRefresh = (originalRequest: any) => {
  axios
    .post(`${URL}/user/token`, null, { withCredentials: true })
    .then((response) => onLoginSuccess(response, originalRequest))
    .catch((error) => {
      handleTokenNull();
    });
};

export const onLoginSuccess = (response: any, originalRequest: any) => {
  if (response) {
    const programIdx = response.programidx;

    const { jwt, usrIdx } =
      response?.data?.authenticatetoken || response?.authenticatetoken;
    // accessToken 설정
    if (jwt && usrIdx) {
      // axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
      store.dispatch(set_token(jwt));
      const numUsridx = Number(usrIdx);
      store.dispatch(set_user_index(numUsridx));
      store.dispatch(set_token(jwt));
      onGetUserinfo(numUsridx, programIdx);
      originalRequest && api(originalRequest);
    } else {
      console.log("token error", response);
      // localInfoReset();
    }
  } else {
    // localInfoReset();
  }
};

export const onGetUserinfo = (usridx: number, programIdx: any) => {
  axios
    .post(`${URL}/common/getuserinfo`, {
      usridx: usridx,
      programidx: programIdx,
    })
    .then(onGetUserinfoSuccess)
    .catch((error) => {
      console.log("/common/getuserinfoerror", error, error.response?.status);
      // ... 로그인 실패 처리
    });
};

export const onGetUserinfoSuccess = (response: any) => {
  const data = response?.data;
  if (data.result === "success") {
    const userinfo = data.userinfo;
    const userbodyinfo = data.userbodyinfo;
    const headerData = {
      ...data.usercashinfo,
      usrnick: userinfo.usrnick,
      usrname: userinfo.usrname,
      profile_url: userinfo.profile_url,
      userbodyinfo: userbodyinfo ? true : false,
      usrtype: userinfo.usrtype,
      channeladdress: userinfo.channeladdress,
    };
    store.dispatch(set_header_info(headerData));
  } else {
    // localInfoReset();
    // if (window.location.pathname !== "/login") window.location.href = "/login";
  }
};

export const localInfoReset = () => {
  store.dispatch(set_token(null));
  store.dispatch(set_user_index(0));
  store.dispatch(set_header_info(null));
  // {
  //   credit: 0,
  //   point: 0,
  //   usrnick: "",
  //   profile_url: null,
  //   userbodyinfo: false,
  //   usrtype: "N",
  // }
  // window.history?.pushState("", "", "/login");
  // window.location.replace("/login");
};

export const handleTokenNull = () => {
  localInfoReset();
  const pathName = window.location.pathname;
  if (
    pathName !== "/" &&
    pathName !== "/trainer" &&
    pathName !== "/login" &&
    pathName !== "/game" &&
    pathName !== "/market" &&
    pathName !== "/cscenter"
  ) {
    if (pathName !== "/login") window.location.replace("/login");
  }
};

//로그아웃
const logout = async (param: any): Promise<any> => {
  const { data } = await axios.post(`${URL}/user/logout`, {
    ...param,
  });
  return data;
};

export const useLogout = (param: any) => {
  return useQuery(["logout", param], () => logout(param), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

//휴면 계정 해지
const unlock = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/user/unlock`, {
    ...param,
  });
  return data;
};

export const useUnlock = (param: any) => {
  return useQuery(["unlock", param], () => unlock(param), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

//사용자 확인
const userCheckuser = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/user/checkuser`, {
    ...param,
  });
  return data;
};

export const useUserCheckuser = (param: any) => {
  return useQuery(["usermembercheck", param], () => userCheckuser(param), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};
