import { ReactComponent as MatIconLined } from "../assets/svg/ic_mat_lined.svg";
import { ReactComponent as DumbbellIconLined } from "../assets/svg/ic_dumbbell_lined.svg";
import { ReactComponent as KettleBellIconLined } from "../assets/svg/ic_kettle_bell_lined.svg";
import { ReactComponent as BandIconLined } from "../assets/svg/ic_band_lined.svg";
import { ReactComponent as GymballIconLined } from "../assets/svg/ic_gymball_lined.svg";
import { ReactComponent as BosuBallIconLined } from "../assets/svg/ic_bosu_ball_lined.svg";
import { ReactComponent as BalanceBoardIconLined } from "../assets/svg/ic_balance_board_lined.svg";
import { ReactComponent as CircleRingIconLined } from "../assets/svg/ic_circle_ring_lined.svg";
import { ReactComponent as Treadmill2IconLined } from "../assets/svg/ic_treadmill_2_lined.svg";
import { ReactComponent as StepBoxIconLined } from "../assets/svg/ic_step_box_lined.svg";
import { ReactComponent as AquaBagIconLined } from "../assets/svg/ic_aqua_bag_lined.svg";
import { ReactComponent as FoamRollerIconLined } from "../assets/svg/ic_foam_roller_lined.svg";
import { ReactComponent as CycleIconLined } from "../assets/svg/ic_cycle_lined.svg";
import { ReactComponent as BodyIconLined } from "../assets/svg/body/ic_body_lined.svg";
import { ReactComponent as HapticSuitLined } from "../assets/svg/ic_haptic_suit.svg";
import { ReactComponent as StrechingBarLined } from "../assets/svg/ic_streching_bar_lined.svg";

//신체
import { ReactComponent as BodyIconWhole } from "../assets/svg/body/ic_body_whole.svg";
import { ReactComponent as BodyIconAbs } from "../assets/svg/body/ic_body_abs.svg";
import { ReactComponent as BodyIconChest } from "../assets/svg/body/ic_body_chest.svg";
import { ReactComponent as BodyIconNeck } from "../assets/svg/body/ic_body_neck.svg";
import { ReactComponent as BodyIconShoulder } from "../assets/svg/body/ic_body_shoulder.svg";
import { ReactComponent as BodyIconArms } from "../assets/svg/body/ic_body_arms.svg";
import { ReactComponent as BodyIconLegs } from "../assets/svg/body/ic_body_legs.svg";
import { ReactComponent as BodyIconBack } from "../assets/svg/body/ic_body_back.svg";
import { ReactComponent as BodyIconHip } from "../assets/svg/body/ic_body_hip.svg";
import { ReactComponent as BodyIconWhole2 } from "../assets/svg/body/ic_body_whole_2.svg";
import { ReactComponent as BodyIconAbs2 } from "../assets/svg/body/ic_body_abs_2.svg";
import { ReactComponent as BodyIconChest2 } from "../assets/svg/body/ic_body_chest_2.svg";
import { ReactComponent as BodyIconNeck2 } from "../assets/svg/body/ic_body_neck_2.svg";
import { ReactComponent as BodyIconShoulder2 } from "../assets/svg/body/ic_body_shoulder_2.svg";
import { ReactComponent as BodyIconArms2 } from "../assets/svg/body/ic_body_arms_2.svg";
import { ReactComponent as BodyIconLegs2 } from "../assets/svg/body/ic_body_legs_2.svg";
import { ReactComponent as BodyIconBack2 } from "../assets/svg/body/ic_body_back_2.svg";
import { ReactComponent as BodyIconHip2 } from "../assets/svg/body/ic_body_hip_2.svg";

//신체 png
import BodyIconWholePng from "../assets/img/body/ic_body_whole.png";
import BodyIconAbsPng from "../assets/img/body/ic_body_abs.png";
import BodyIconChestPng from "../assets/img/body/ic_body_chest.png";
import BodyIconNeckPng from "../assets/img/body/ic_body_neck.png";
import BodyIconShoulderPng from "../assets/img/body/ic_body_shoulder.png";
import BodyIconArmsPng from "../assets/img/body/ic_body_arms.png";
import BodyIconLegsPng from "../assets/img/body/ic_body_legs.png";
import BodyIconBackPng from "../assets/img/body/ic_body_back.png";
import BodyIconHipPng from "../assets/img/body/ic_body_hip.png";
import BodyIconWhole2Png from "../assets/img/body/ic_body_whole_2.png";
import BodyIconAbs2Png from "../assets/img/body/ic_body_abs_2.png";
import BodyIconChest2Png from "../assets/img/body/ic_body_chest_2.png";
import BodyIconNeck2Png from "../assets/img/body/ic_body_neck_2.png";
import BodyIconShoulder2Png from "../assets/img/body/ic_body_shoulder_2.png";
import BodyIconArms2Png from "../assets/img/body/ic_body_arms_2.png";
import BodyIconLegs2Png from "../assets/img/body/ic_body_legs_2.png";
import BodyIconBack2Png from "../assets/img/body/ic_body_back_2.png";
import BodyIconHip2Png from "../assets/img/body/ic_body_hip_2.png";

//필터
import { ReactComponent as BodyPoseUpperLinedIcon } from "../assets/svg/ic_body_pose_upper_lined.svg";
import { ReactComponent as CalendarLinedIcon } from "../assets/svg/ic_calendar_lined.svg";
import { ReactComponent as ClockOutlineLinedIcon } from "../assets/svg/ic_clock_outline_lined.svg";

//트레이너 필터
// import { ReactComponent as BodyPoseArmsLinedIcon } from "../assets/svg/ic_body_pose_arms_lined.svg";
// import { ReactComponent as BodyPoseLinedIcon } from "../assets/svg/ic_body_pose_lined.svg";
// import { ReactComponent as TrophyLinedIcon } from "../assets/svg/ic_trophy_lined.svg";

//운동
import { ReactComponent as PilatesLinedIcon } from "../assets/svg/ic_pilates_lined.svg";
import { ReactComponent as YogaLinedIcon } from "../assets/svg/ic_yoga_lined.svg";
import { ReactComponent as KettleBellLined } from "../assets/svg/ic_kettle_bell_lined.svg";

// const cdnAddress = "http://192.168.0.100:8080/metagym/";
const cdnAddress = "https://cdn.metafamily.world/";

export const STARTMOVIE = `${cdnAddress}common/exercise/av1/1_Start.mp4`;
export const ENDMOVIE = `${cdnAddress}common/exercise/av1/3_End.mp4`;
export const RESTMOVIE = `${cdnAddress}common/exercise/av1/2_BrakeTime.mp4`;

export const STARTMOVIE_EN = `${cdnAddress}common/exercise/av1/1_Start_EN.mp4`;
export const ENDMOVIE_EN = `${cdnAddress}common/exercise/av1/3_End_EN.mp4`;
export const RESTMOVIE_EN = `${cdnAddress}common/exercise/av1/2_BrakeTime_EN.mp4`;

export const exExerciseTypeData1 = [
  { icon: <DumbbellIconLined /> },
  { icon: <CycleIconLined /> },
  { icon: <CircleRingIconLined /> },
];

export const exExerciseTypeData2 = [
  { icon: <DumbbellIconLined />, text: "테스트" },
  { icon: <DumbbellIconLined />, text: "테스트" },
  { icon: <DumbbellIconLined />, text: "테스트" },
];

export const exerciseGoals = [
  {
    key: "exerciseGoals_0",
    text: "MyInfo_BodyInfoTab_DropDownBtn_1", //건강유지
    value: 1,
  },
  {
    key: "exerciseGoals_1",
    text: "MyInfo_BodyInfoTab_DropDownBtn_2", //다이어트
    value: 2,
  },
  {
    key: "exerciseGoals_2",
    text: "MyInfo_BodyInfoTab_DropDownBtn_3", //몸매 만들기
    value: 3,
  },
  {
    key: "exerciseGoals_3",
    text: "MyInfo_BodyInfoTab_DropDownBtn_4", //체력향상
    value: 4,
  },
  {
    key: "exerciseGoals_4",
    text: "MyInfo_BodyInfoTab_DropDownBtn_5", //근력 및 근육 강화
    value: 5,
  },
  {
    key: "exerciseGoals_5",
    text: "MyInfo_BodyInfoTab_DropDownBtn_6", //근파워
    value: 6,
  },
  {
    key: "exerciseGoals_6",
    text: "MyInfo_BodyInfoTab_DropDownBtn_7", //유연성 향상
    value: 7,
  },
];

export const exerciseCount = [
  {
    key: "exerciseCount_0",
    text: "MyInfo_BodyInfoTab_DropDownBtn_8", //주 1회
    value: 1,
  },
  {
    key: "exerciseCount_1",
    text: "MyInfo_BodyInfoTab_DropDownBtn_9", //주 2회
    value: 2,
  },
  {
    key: "exerciseCount_2",
    text: "MyInfo_BodyInfoTab_DropDownBtn_10", //주 3회
    value: 3,
  },
  {
    key: "exerciseCount_3",
    text: "MyInfo_BodyInfoTab_DropDownBtn_11", //주 4회
    value: 4,
  },
  {
    key: "exerciseCount_4",
    text: "MyInfo_BodyInfoTab_DropDownBtn_12", //주 5회
    value: 5,
  },
  {
    key: "exerciseCount_5",
    text: "MyInfo_BodyInfoTab_DropDownBtn_13", //주 6회
    value: 6,
  },
  {
    key: "exerciseCount_6",
    text: "MyInfo_BodyInfoTab_DropDownBtn_14", //매일
    value: 7,
  },
];

export const exerciseTools = [
  {
    id: "nothing",
    text: "MyInfo_BodyInfoTab_InputSubTitle_4", //없음
    icon: "",
    index: 20,
  },
  {
    id: "mat",
    text: "Exercise_Tool_Name_3", //매트
    icon: <MatIconLined />,
    index: 22,
  },
  {
    id: "dumbbell",
    text: "Exercise_Tool_Name_4", //덤벨
    icon: <DumbbellIconLined />,
    index: 23,
  },
  {
    id: "kettleBell",
    text: "Exercise_Tool_Name_5", //케틀벨
    icon: <KettleBellIconLined />,
    index: 24,
  },
  {
    id: "band",
    text: "Exercise_Tool_Name_2", //밴드
    icon: <BandIconLined />,
    index: 21,
  },
  {
    id: "gymball",
    text: "Exercise_Tool_Name_6", //짐볼
    icon: <GymballIconLined />,
    index: 25,
  },
  {
    id: "bosuBall",
    text: "Exercise_Tool_Name_7", //보수
    icon: <BosuBallIconLined />,
    index: 26,
  },
  {
    id: "balanceBoard",
    text: "Exercise_Tool_Name_8", //밸런스보드
    icon: <BalanceBoardIconLined />,
    index: 27,
  },
  {
    id: "circleRing",
    text: "Exercise_Tool_Name_9", //서클링
    icon: <CircleRingIconLined />,
    index: 28,
  },
  {
    id: "treadmill",
    text: "Exercise_Tool_Name_10", //트레드밀
    icon: <Treadmill2IconLined />,
    index: 29,
  },
  {
    id: "stepBox",
    text: "Exercise_Tool_Name_11", //스텝박스
    icon: <StepBoxIconLined />,
    index: 30,
  },
  {
    id: "aquaBag",
    text: "Exercise_Tool_Name_12", //아쿠아백
    icon: <AquaBagIconLined />,
    index: 31,
  },
  {
    id: "foamRoller",
    text: "Exercise_Tool_Name_13", //폼롤러
    icon: <FoamRollerIconLined />,
    index: 32,
  },
  {
    id: "cycle",
    text: "Library_Tag_Name_59", //싸이클
    icon: <CycleIconLined />,
    index: 33,
  },
];

export const exerciseTools2 = [
  {
    id: "body",
    text: "Exercise_Tool_Name_1",
    icon: <BodyIconLined />,
    value: 20,
  },
  {
    id: "mat",
    text: "Exercise_Tool_Name_3",
    icon: <MatIconLined />,
    value: 22,
  },
  {
    id: "dumbbell",
    text: "Exercise_Tool_Name_4",
    icon: <DumbbellIconLined />,
    value: 23,
  },
  {
    id: "kettleBell",
    text: "Exercise_Tool_Name_5",
    icon: <KettleBellIconLined />,
    value: 24,
  },
  {
    id: "band",
    text: "Exercise_Tool_Name_2",
    icon: <BandIconLined />,
    value: 21,
  },
  {
    id: "gymball",
    text: "Exercise_Tool_Name_6",
    icon: <GymballIconLined />,
    value: 25,
  },
  {
    id: "bosuBall",
    text: "Exercise_Tool_Name_7",
    icon: <BosuBallIconLined />,
    value: 26,
  },
  {
    id: "balanceBoard",
    text: "Exercise_Tool_Name_8",
    icon: <BalanceBoardIconLined />,
    value: 27,
  },
  {
    id: "circleRing",
    text: "Exercise_Tool_Name_9",
    icon: <CircleRingIconLined />,
    value: 28,
  },
  {
    id: "treadmill2",
    text: "Exercise_Tool_Name_10",
    icon: <Treadmill2IconLined />,
    value: 29,
  },
  {
    id: "stepBox",
    text: "Exercise_Tool_Name_11",
    icon: <StepBoxIconLined />,
    value: 30,
  },
  {
    id: "aquaBag",
    text: "Exercise_Tool_Name_12",
    icon: <AquaBagIconLined />,
    value: 31,
  },
  {
    id: "foamRoller",
    text: "Exercise_Tool_Name_13",
    icon: <FoamRollerIconLined />,
    value: 32,
  },
  {
    id: "cycle",
    text: "Exercise_Tool_Name_14",
    icon: <CycleIconLined />,
    value: 33,
  },
  {
    id: "hapticSuit",
    text: "Exercise_Tool_Name_15",
    icon: <HapticSuitLined />,
    value: 107,
  },
  {
    id: "strechingBar",
    text: "Exercise_Tool_Name_16",
    icon: <StrechingBarLined />,
    value: 34,
  },
];

export const surveyItem1 = [
  {
    id: "drinkingCount",
    title: "MyInfo_SurveyInfoTab_InputTitle_1",
    datas: [
      {
        key: "drinkingCount_0",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_19", //해당사항 없음
        value: 9,
      },
      {
        key: "drinkingCount_1",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_20", //1회
        value: 1,
      },
      {
        key: "drinkingCount_2",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_21", //2회
        value: 2,
      },
      {
        key: "drinkingCount_3",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_22", //3회
        value: 3,
      },
      {
        key: "drinkingCount_4",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_23", //4회
        value: 4,
      },
      {
        key: "drinkingCount_5",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_24", //5회
        value: 5,
      },
      {
        key: "drinkingCount_6",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_25", //6회
        value: 6,
      },
      {
        key: "drinkingCount_7",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_26", //매일
        value: 7,
      },
    ],
    placeholder: "MyInfo_SurveyInfoTab_Guide_1",
  },
  {
    id: "drinkingAmount",
    title: "MyInfo_SurveyInfoTab_InputTitle_2",
    datas: [
      {
        key: "drinkingAmount_0",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_5", //반 병 이하
        value: 1,
      },
      {
        key: "drinkingAmount_1",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_6", //1병
        value: 2,
      },
      {
        key: "drinkingAmount_2",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_7", //1병 반
        value: 3,
      },
      {
        key: "drinkingAmount_3",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_8", //2병
        value: 4,
      },
      {
        key: "drinkingAmount_4",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_9", //2병 이상
        value: 5,
      },
    ],
    placeholder: "MyInfo_SurveyInfoTab_Guide_2",
  },
  {
    id: "smokingAmount",
    title: "MyInfo_SurveyInfoTab_InputTitle_3",
    datas: [
      {
        key: "smokingAmount_9",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_19", //해당사항 없음
        value: 9,
      },
      {
        key: "smokingAmount_0",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_2", //반 갑 이하
        value: 1,
      },
      {
        key: "smokingAmount_1",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_1", //반 갑
        value: 2,
      },
      {
        key: "smokingAmount_2",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_3", //1갑
        value: 3,
      },
      {
        key: "smokingAmount_3",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_4", //2갑 이상
        value: 4,
      },
    ],
    placeholder: "MyInfo_SurveyInfoTab_Guide_3",
  },
  {
    id: "cardiovascular",
    title: "MyInfo_SurveyInfoTab_InputTitle_4",
    datas: [
      {
        key: "cardiovascular_0",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_10", //해당 사항 없음
        value: 1,
      },
      {
        key: "cardiovascular_1",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_11", //저 위험 군
        value: 2,
      },
      {
        key: "cardiovascular_2",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_12", //중등 위험 군
        value: 3,
      },
      {
        key: "cardiovascular_3",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_13", //고 위험 군
        value: 4,
      },
    ],
    placeholder: "MyInfo_SurveyInfoTab_Guide_4",
  },
  {
    id: "lung",
    title: "MyInfo_SurveyInfoTab_InputTitle_5",
    datas: [
      { key: "lung_0", text: "MyInfo_SurveyInfoTab_DropDownBtn_10", value: 1 }, //해당 사항 없음
      { key: "lung_1", text: "MyInfo_SurveyInfoTab_DropDownBtn_11", value: 2 }, //저 위험 군
      { key: "lung_2", text: "MyInfo_SurveyInfoTab_DropDownBtn_12", value: 3 }, //중등 위험 군
      { key: "lung_3", text: "MyInfo_SurveyInfoTab_DropDownBtn_13", value: 4 }, //고 위험 군
    ],
    placeholder: "MyInfo_SurveyInfoTab_Guide_4",
  },
  {
    id: "highBlood",
    title: "MyInfo_SurveyInfoTab_InputTitle_6",
    datas: [
      {
        key: "highBlood_0",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_10", //해당 사항 없음
        value: 1,
      },
      {
        key: "highBlood_1",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_11", //저 위험 군
        value: 2,
      },
      {
        key: "highBlood_2",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_12", //중등 위험 군
        value: 3,
      },
      {
        key: "highBlood_3",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_13", //고 위험 군
        value: 4,
      },
    ],
    placeholder: "MyInfo_SurveyInfoTab_Guide_4",
  },
  {
    id: "diabetes",
    title: "MyInfo_SurveyInfoTab_InputTitle_7", //당뇨
    datas: [
      {
        key: "diabetes_0",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_10", //해당 사항 없음
        value: 1,
      },
      {
        key: "diabetes_1",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_11", //저 위험 군
        value: 2,
      },
      {
        key: "diabetes_2",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_12", //중등 위험 군
        value: 3,
      },
      {
        key: "diabetes_3",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_13", //고 위험 군
        value: 4,
      },
    ],
    placeholder: "MyInfo_SurveyInfoTab_Guide_4",
  },
];

export const surveyItem2: any = [
  {
    id: "exerciseTerm",
    title: "MyInfo_SurveyInfoTab_InputTitle_8",
    datas: [
      {
        key: "exerciseTerm_0",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_14", //휴식 중
        value: 1,
      },
      {
        key: "exerciseTerm_1",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_15", //1개월 유지
        value: 2,
      },
      {
        key: "exerciseTerm_2",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_16", //3개월 유지
        value: 3,
      },
      {
        key: "exerciseTerm_3",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_17", //6개월 유지
        value: 4,
      },
      {
        key: "exerciseTerm_4",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_18", //1년 이상 유지
        value: 5,
      },
    ],
    placeholder: "MyInfo_SurveyInfoTab_Guide_5",
  },
  {
    id: "exerciseCount",
    title: "MyInfo_SurveyInfoTab_InputTitle_9",
    datas: [
      {
        key: "exerciseCount_1",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_27", //1회
        value: 1,
      },
      {
        key: "exerciseCount_2",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_28", //2회
        value: 2,
      },
      {
        key: "exerciseCount_3",
        text: "MyInfo_SurveyInfoTab_DropDownBtn_29", //3회 이상
        value: 3,
      },
    ],
    placeholder: "MyInfo_SurveyInfoTab_Msg_1",
  },
  {
    id: "exerciseKind",
    title: "MyInfo_SurveyInfoTab_InputTitle_10",
    datas: [
      {
        id: "bike",
        text: "MyInfo_SurveyInfoTab_InputSubTitle_1",
        value: 1,
      },
      {
        id: "weight",
        text: "MyInfo_SurveyInfoTab_InputSubTitle_2",
        value: 2,
      },
      {
        id: "running",
        text: "MyInfo_SurveyInfoTab_InputSubTitle_3",
        value: 3,
      },
      {
        id: "climbing",
        text: "MyInfo_SurveyInfoTab_InputSubTitle_4",
        value: 4,
      },
      {
        id: "swimming",
        text: "MyInfo_SurveyInfoTab_InputSubTitle_5",
        value: 5,
      },
      {
        id: "ballGame",
        text: "MyInfo_SurveyInfoTab_InputSubTitle_6",
        value: 6,
      },
      {
        id: "walking",
        text: "MyInfo_SurveyInfoTab_InputSubTitle_7",
        value: 7,
      },
      {
        id: "etc",
        text: "MyInfo_SurveyInfoTab_InputSubTitle_8",
        value: 8,
      },
    ],
    placeholder: "MyInfo_SurveyInfoTab_Guide_4",
  },
];

export const bodyCate: Array<any> = [
  { key: "gender", text: "MyInfo_BodyInfoTab_InputTitle_1", value: 1 },
  { key: "age", text: "MyInfo_BodyInfoTab_InputTitle_1", value: 2 },
  { key: "height", text: "MyInfo_BodyInfoTab_InputTitle_2", value: 3 },
  { key: "weight", text: "MyInfo_BodyInfoTab_InputTitle_2", value: 4 },
  { key: "goal", text: "MyInfo_BodyInfoTab_InputTitle_3", value: 5 },
  { key: "tools", text: "MyInfo_BodyInfoTab_InputTitle_4", value: 6 },
  { key: "count", text: "MyInfo_BodyInfoTab_InputTitle_5", value: 7 },
];

export const exerciseBodys = [
  {
    id: "whole",
    text: "Body_Name_19",
    icon: <BodyIconWhole />,
    icon2: <BodyIconWhole2 />,
    value: 10,
  },
  // {
  //   id: "abs",
  //   text: "Body_Name_20",
  //   icon: <BodyIconAbs />,
  //   icon2: <BodyIconAbs2 />,
  //   value: 2,
  // },
  {
    id: "abs2",
    text: "Body_Name_11",
    icon: BodyIconAbsPng,
    icon2: BodyIconAbs2Png,
    value: 16,
  },
  {
    id: "chest",
    text: "Body_Name_1",
    icon: <BodyIconChest />,
    icon2: <BodyIconChest2 />,
    value: 15,
  },
  {
    id: "neck",
    text: "Body_Name_8",
    icon: <BodyIconNeck />,
    icon2: <BodyIconNeck2 />,
    value: 11,
  },
  {
    id: "shoulder",
    text: "Body_Name_15",
    icon: <BodyIconShoulder />,
    icon2: <BodyIconShoulder2 />,
    value: 13,
  },
  {
    id: "arms",
    text: "Body_Name_21",
    icon: <BodyIconArms />,
    icon2: <BodyIconArms2 />,
    value: 14,
  },
  {
    id: "legs",
    text: "Body_Name_22",
    icon: <BodyIconLegs />,
    icon2: <BodyIconLegs2 />,
    value: 18,
  },
  {
    id: "back",
    text: "Body_Name_2",
    icon: <BodyIconBack />,
    icon2: <BodyIconBack2 />,
    value: 12,
  },
  {
    id: "hip",
    text: "Body_Name_12",
    icon: <BodyIconHip />,
    icon2: <BodyIconHip2 />,
    value: 17,
  },
];

export const bodyCommonCode: ExCommonCodeType2[] = [
  {
    id: "whole",
    text: "Body_Name_19",
    icon: BodyIconWholePng,
    icon2: BodyIconWhole2Png,
    value: 10,
  },
  // {
  //   id: "abs",
  //   text: "Body_Name_20",
  //   icon: BodyIconAbsPng,
  //   icon2: BodyIconAbs2Png,
  //   value: 2,
  // },
  {
    id: "abs2",
    text: "Body_Name_11",
    icon: BodyIconAbsPng,
    icon2: BodyIconAbs2Png,
    value: 16,
  },
  {
    id: "chest",
    text: "Body_Name_1",
    icon: BodyIconChestPng,
    icon2: BodyIconChest2Png,
    value: 15,
  },
  {
    id: "neck",
    text: "Body_Name_8",
    icon: BodyIconNeckPng,
    icon2: BodyIconNeck2Png,
    value: 11,
  },
  {
    id: "shoulder",
    text: "Body_Name_15",
    icon: BodyIconShoulderPng,
    icon2: BodyIconShoulder2Png,
    value: 13,
  },
  {
    id: "arms",
    text: "Body_Name_21",
    icon: BodyIconArmsPng,
    icon2: BodyIconArms2Png,
    value: 14,
  },
  {
    id: "legs",
    text: "Body_Name_22",
    icon: BodyIconLegsPng,
    icon2: BodyIconLegs2Png,
    value: 18,
  },
  {
    id: "back",
    text: "Body_Name_2",
    icon: BodyIconBackPng,
    icon2: BodyIconBack2Png,
    value: 12,
  },
  {
    id: "hip",
    text: "Body_Name_12",
    icon: BodyIconHipPng,
    icon2: BodyIconHip2Png,
    value: 17,
  },
];

export const ExSearchFilterDatas = [
  {
    title: "Exercise_Search_Filter_Contents_SubTitle_1", //"운동 도구",
    id: "exerciseTool",
    icon: <GymballIconLined />,
    list: exerciseTools2,
  },
  {
    title: "Exercise_Search_Filter_Contents_SubTitle_2", //"운동 종류",
    id: "exerciseType",
    icon: <BodyPoseUpperLinedIcon />,
    list: [
      {
        id: "weight",
        text: "Exercise_Search_Filter_Contents_Type2_Btn_1", //"웨이트"
        icon: <KettleBellLined />,
        value: 1,
      },
      {
        id: "pilates",
        text: "Exercise_Search_Filter_Contents_Type2_Btn_2", //"필라테스"
        icon: <PilatesLinedIcon />,
        value: 2,
      },
      {
        id: "yoga",
        text: "Exercise_Search_Filter_Contents_Type2_Btn_3", //"요가"
        icon: <YogaLinedIcon />,
        value: 3,
      },
    ],
  },
  {
    title: "Exercise_Search_Filter_Contents_SubTitle_3", //"운동 기간",
    id: "weeks",
    icon: <CalendarLinedIcon />,
    list: [
      {
        id: "week1",
        text: "Exercise_Search_Filter_Contents_Type3_Btn_1", //1~4주"
        value: 1,
      },
      {
        id: "week5",
        text: "Exercise_Search_Filter_Contents_Type3_Btn_2", //"5~8주"
        value: 2,
      },
      {
        id: "week9",
        text: "Exercise_Search_Filter_Contents_Type3_Btn_3", //"9~12주"
        value: 3,
      },
    ],
  },
  {
    title: "Exercise_Search_Filter_Contents_SubTitle_4", //"주간 운동 일수",
    id: "daysAWeek",
    icon: <CalendarLinedIcon />,
    list: [
      {
        id: "day1",
        text: "Exercise_Search_Filter_Contents_Type4_Btn_1", //"주 1일"
        value: 1,
      },
      {
        id: "day2",
        text: "Exercise_Search_Filter_Contents_Type4_Btn_2", //"주 2일"
        value: 2,
      },
      {
        id: "day3",
        text: "Exercise_Search_Filter_Contents_Type4_Btn_3", //"주 3일"
        value: 3,
      },
      {
        id: "day4",
        text: "Exercise_Search_Filter_Contents_Type4_Btn_4", //"주 4일"
        value: 4,
      },
      {
        id: "day5",
        text: "Exercise_Search_Filter_Contents_Type4_Btn_5", //"주 5일"
        value: 5,
      },
      {
        id: "day6",
        text: "Exercise_Search_Filter_Contents_Type4_Btn_6", //"주 6일"
        value: 6,
      },
      {
        id: "day7",
        text: "Exercise_Search_Filter_Contents_Type4_Btn_7", //"매일"
        value: 7,
      },
    ],
  },
  {
    title: "Exercise_Search_Filter_Contents_SubTitle_5", //"일 평균 운동 시간",
    id: "avgTime",
    icon: <ClockOutlineLinedIcon />,
    list: [
      {
        id: "time1",
        text: "Exercise_Search_Filter_Contents_Type5_Btn_1", //"0~30분"
        value: 1,
      },
      {
        id: "time2",
        text: "Exercise_Search_Filter_Contents_Type5_Btn_2", //"30분~1시간"
        value: 2,
      },
      {
        id: "time3",
        text: "1시간~1시간30분", //"1시간~1시간30분"
        value: 3,
      },
      {
        id: "time4",
        text: "Exercise_Search_Filter_Contents_Type5_Btn_3", //"1시간30분~2시간"
        value: 4,
      },
      {
        id: "time5",
        text: "Exercise_Search_Filter_Contents_Type5_Btn_4", //"2시간 이상"
        value: 5,
      },
    ],
  },
];

export const ExSortList = [
  {
    id: "base",
    text: "Exercise_SearchResult_DropDownBtn_1",
    type: 1,
    value: "default",
  },
  {
    id: "new",
    text: "Exercise_SearchResult_DropDownBtn_2",
    type: 2,
    value: "newest",
  },
  {
    id: "old",
    text: "Exercise_SearchResult_DropDownBtn_3",
    type: 3,
    value: "oldest",
  },
];
// (로그인)
// 1. 내가 좋아할 만한 운동: likepartlist
// 2. 내가 가진 장비로 할 수 있는 운동: toollist
// 3. 나에게 부족한 운동: nolikepartlist
// 4. 내가 찜한 운동: bookmarklist
// --------------------------------------------------
// (비로그인)
// 5. 메타짐 인기 운동: popularlist
// 6. 이번주 새로 올라온 운동: thisweeklist
// 7. 평점이 높은 운동: scorelist
// 8. 상체 운동: upperlist
// 9. 하체 운동: lowerlist
// 10. 전체 운동: wholelist

export const ExNavigation = [
  {
    id: "likepartlist",
    text: "내가 좋아할 만한 운동",
  },
  {
    id: "toollist",
    text: "내가 가진 장비로 할 수 있는 운동",
  },
  {
    id: "nolikepartlist",
    text: "나에게 부족한 운동",
  },
  {
    id: "bookmarklist",
    text: "내가 찜한 운동",
  },
  {
    id: "popularlist",
    text: "메타짐 인기 운동",
  },
  {
    id: "thisweeklist",
    text: "이번주 새로 올라온 운동",
  },
  {
    id: "scorelist",
    text: "평점이 높은 운동",
  },
  {
    id: "upperlist",
    text: "상체 운동",
  },
  {
    id: "lowerlist",
    text: "하체 운동",
  },
  {
    id: "wholelist",
    text: "전신 운동",
  },
];

export const ExTypeNavigatio = [
  {
    id: "neck",
    text: "목 운동 치료",
  },
  {
    id: "shoulder",
    text: "어깨 운동 치료",
  },
  {
    id: "waist",
    text: "허리 운동 치료",
  },
  {
    id: "hipjoint",
    text: "고관절 운동 치료",
  },
  {
    id: "knee",
    text: "무릎 운동 치료",
  },
  {
    id: "foot",
    text: "발 운동 치료",
  },
  {
    id: "core",
    text: "코어 운동 치료",
  },
];

export const ExNavigationMy = [
  ...ExTypeNavigatio,
  {
    id: "myproductlist",
    text: "Category_Name_1", //"내가 만든 운동"
  },
  {
    id: "lower",
    text: "", // "하체 운동 프로그램"
  },
  {
    id: "diet",
    text: "", // "다이어트 운동 프로그램"
  },
  {
    id: "beginner",
    text: "", //"초보자 가이드 프로그램"
  },
  {
    id: "popular",
    text: "", //"인기 운동 프로그램"
  },
  {
    id: "highstrength",
    text: "", // "고강도 운동 프로그램"
  },
  {
    id: "wholebody",
    text: "", //"전신 운동 프로그램"
  },
  ...ExNavigation,
];
